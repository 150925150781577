<!-- broadcast-remark -->
<template>
  <el-drawer
    class="broadcast-remark"
    :visible.sync="open"
    :title="title"
    size="25%"
    :modal="false"
    @open="handleDrawerOpen"
  >
    <template v-slot:title>
      <custom-title :title="title" />
    </template>

    <div class="broadcast-remark-operate">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="handleOperateAddClick"
        >新增
      </el-button>
    </div>

    <el-scrollbar class="broadcast-remark-scrollbar" v-loading="loading">
      <!-- 开播记录备注空占位符 -->
      <empty-placeholder
        v-if="!broadcastRemarks || !broadcastRemarks.length"
        placeholder="暂无备注"
      />

      <el-timeline v-else class="broadcast-remark-timeline">
        <el-timeline-item
          v-for="remark in broadcastRemarks"
          placement="top"
          size="large"
          :timestamp="remark.createTime"
          :key="remark._id"
        >
          <!-- 时间线项操作容器 -->
          <div
            v-if="
              currentUserHasFullPermissions || remark.userId === currentUserId
            "
            class="timeline-item-operate"
          >
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="handleTimelineItemEditClick(remark)"
            />

            <el-button
              class="operate-remove"
              type="text"
              icon="el-icon-delete"
              size="mini"
              @click="handleTimelineItemRemoveClick(remark)"
            />
          </div>

          <!-- 时间线项内容容器 -->
          <el-card class="timeline-item-card">
            <div class="item-title" v-html="genTimelineTitle(remark)" />
            <div class="item-content">{{ remark.content }}</div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </el-scrollbar>

    <!-- 新增/编辑备注对话框 -->
    <broadcast-remark-edit
      v-model="showEditDialog"
      @on-ok="handleBroadcastRemarkEditDialogOkClick"
    />

    <!-- 删除备注对话框 -->
    <broadcast-remark-remove
      v-model="showRemoveDialog"
      :ok-button-loading="removing"
      @on-ok="handleBroadcastRemarkRemoveDialogOkClick"
    />
  </el-drawer>
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import CustomTitle from '../../../../components/custom-title';
  import EmptyPlaceholder from '../../../../components/empty-placeholder';
  import { removeBroadcastRemark } from '../../../../api/anchor/broadcast';

  export default {
    name: 'broadcast-remark',
    components: {
      EmptyPlaceholder,
      CustomTitle,
      BroadcastRemarkEdit: () =>
        import(/* webpackChunkName: 'broadcast-remark-edit' */ './remark-edit'),
      BroadcastRemarkRemove: () =>
        import(
          /* webpackChunkName: 'broadcast-remark-remove' */ '../../../../components/confirm'
        ),
    },
    props: {
      // 是否开启抽屉
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        operateId: '',
        showEditDialog: false,
        showRemoveDialog: false,
        loading: false,
        removing: false,
      };
    },
    computed: {
      ...mapState('anchor/broadcast', ['broadcastRemarks']),
      ...mapGetters(['currentUserId', 'currentUserHasFullPermissions']),
      ...mapGetters('anchor/broadcast', ['broadcastAnchorName']),

      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 抽屉标题
      title() {
        return `${this.broadcastAnchorName} - 开播记录备注`;
      },
    },
    beforeDestroy() {
      this.mttBroadcastRemarkId();
    },
    methods: {
      ...mapMutations('anchor/broadcast', ['mttBroadcastRemarkId']),
      ...mapActions('anchor/broadcast', ['actBroadcastRemarks']),

      // 生成时间线 title
      genTimelineTitle(remark) {
        const { username = '' } = remark;

        return `<span class="highlight">${username}</span>添加如下备注:`;
      },
      // 获取开播记录备注列表
      async getBroadcastRemarks() {
        this.loading = true;

        const success = await this.actBroadcastRemarks();

        this.loading = false;

        return success;
      },
      // 删除签约备注
      async removeBroadcastRemark() {
        const id = this.operateId;

        if (!id) return;

        this.removing = true;

        const res = await removeBroadcastRemark(id);

        this.removing = false;

        return !!res;
      },
      // 抽屉打开
      handleDrawerOpen() {
        this.getBroadcastRemarks();
      },
      // 操作栏新增按钮单击
      handleOperateAddClick() {
        this.operateId = '';
        this.showEditDialog = true;
      },
      // 开播记录备注项编辑按钮单击
      handleTimelineItemEditClick({ _id }) {
        this.operateId = _id;
        this.showEditDialog = true;
      },
      // 开播记录备注项删除按钮单击
      handleTimelineItemRemoveClick({ _id }) {
        this.operateId = _id;
        this.showRemoveDialog = true;
      },
      // 新增/编辑签约备注对话框确定按钮执行成功
      handleBroadcastRemarkEditDialogOkClick() {
        this.getBroadcastRemarks();
      },
      // 删除签约备注对话框确定按钮单击
      async handleBroadcastRemarkRemoveDialogOkClick() {
        const success = await this.removeBroadcastRemark();

        if (!success) return;

        this.showRemoveDialog = false;

        this.getBroadcastRemarks();
      },
    },
    watch: {
      operateId(n, o) {
        this.mttBroadcastRemarkId(n);
      },
    },
  };
</script>

<style scoped lang="scss">
  .broadcast-remark {
    /deep/.el-drawer__header {
      margin-bottom: 15px;
    }

    /deep/ .el-drawer__body {
      flex-direction: column;

      .broadcast-remark-operate {
        padding: 0 15px;
        margin-bottom: 20px;
      }

      .broadcast-remark-scrollbar {
        flex-grow: 1;

        .el-scrollbar__wrap {
          height: 100%;
          overflow: hidden auto;
        }
      }

      .broadcast-remark-timeline {
        padding: 0 15px;

        .el-timeline-item {
          .el-timeline-item__content {
            position: relative;
          }

          // 时间线项操作容器
          .timeline-item-operate {
            position: absolute;
            top: -28px;
            right: 0;
            z-index: 1000;
            display: flex;
            justify-content: flex-end;
            width: 0;
            overflow: hidden;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;

            .el-button {
              min-width: auto;
              width: 22px;

              &.operate-remove {
                color: #f56c6c;
              }
            }
          }

          // 时间线项内容容器
          .timeline-item-card {
            line-height: 22px;
            font-size: 13px;
            color: #777;

            .item-title {
              > .highlight {
                padding: 0 5px;
                color: #409eff;

                &:nth-of-type(1) {
                  padding-left: 0;
                }
              }
            }
          }

          &:hover .timeline-item-operate {
            width: 60px;
            opacity: 1;
          }
        }
      }
    }
  }
</style>
